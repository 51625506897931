

.title {
    font-weight: bold !important;
    font-size: 0.9rem !important;
}

.sub-title {
    font-size: 0.8rem;
    padding: 0px 4px;
    margin-top: 0px;
}
