.round-div-saudi {
    width: 25px;
    height: 25px;
    border-radius: 12px;
    background-color: #006933;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1;

    span {
        text-align: center;
        padding-bottom: 15px;
        font-size: 12px;
        font-weight: bold;
    }
}
.round-div-nonSaudi {
    width: 25px;
    height: 25px;
    border-radius: 12px;
    background-color: #bf4d3a;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1;

    span {
        text-align: center;
        padding-bottom: 15px;
        font-size: 12px;
        font-weight: bold;
    }
}
