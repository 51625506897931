.c-span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.c-MuiAccordion {
    width: 100%;

    &.MuiAccordion-root {
        box-shadow: none !important;
        position: inherit !important;
        padding: 0px;
        margin: 0px !important;
    }
}

.input-time {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    display: block;
    padding: 8.5px 14px;
    font-family: Cairo;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    cursor: text;
    border-radius: 4px;
    border: 1px solid silver;
    width: 90%;
    margin: auto;
    height: 33px;
    margin-top: -4px;
}

.dRXQYT {
    min-height: 15px !important;
}




@media only screen and (max-width: 600px) {
    .drawer {
        display: none;
    }

    .MuiAppBar-root {
        max-width: 100%;
    }
}