.onboarding {
    .MuiLinearProgress-root {
        border-radius: 5px !important;
        height: 5px !important;
    }

    .container-box {
        margin: 20px 0;
    }

    .style-label {
        background: #2a65a5;
        color: #fff;
        padding: 1px 10px;
        border-radius: 5px;
        position: absolute;
        top: -13px;
    }

    .border-top-section {
        height: 100px;
        border-top: 3px solid #b7d3e7;
        position: relative;
        z-index: 0;
    }

    .border-all-section {
        height: 100px;
        border: 3px solid #b7d3e7;
        position: relative;
    }
    .icon-1 {
        display: flex;
        justify-content: center;
        margin-top: -13px;
    }
    .icon-1 label {
        position: absolute;
        top: -40px;
    }

    .icon-1 svg {
        background-color: #fff;
    }

    .icon-2 {
        display: flex;
        justify-content: start;
        margin-top: -13px;
    }
    .icon-2 label {
        position: absolute;
        top: -40px;
    }

    .icon-2 svg {
        background-color: #fff;
        position: relative;
        border-radius: 50%;
        padding: 1.5px;
    }

    .icon-3 {
        display: flex;
        justify-content: center;
        margin-top: -24px;
        position: relative;
    }
    .icon-3 label {
        position: absolute;
        top: -27px;
    }

    .icon-3 svg {
        background-color: #fff;
    }

    .container-icon {
        display: flex;
        justify-content: space-around;
        height: 100%;
        align-items: end;
        margin-top: 8px;
        position: relative;
    }

    .container-icon .icon svg {
        background: #fff;
    }

    .container-icon .icon-4 svg ,  .icon-5 svg ,  .icon-6 svg ,  .icon-7 svg ,  .icon-8 svg {
        background-color: #fff;
        position: relative;
    }

    .icon-4 {
        position: relative;
    }

    .icon-4 label {
        position: absolute;
        top: -27px;
        right: 0;
    }

    .icon-5 {
        position: relative;
    }

    .icon-5 label {
        position: absolute;
        white-space: nowrap;
        top: -27px;
        right: -30px;
    }

    .icon-6 {
        position: relative;
    }

    .icon-6 label {
        position: absolute;
        white-space: nowrap;
        top: -27px;
        right: -35px;
    }

    .icon-7 {
        position: relative;
    }

    .icon-7 label {
        position: absolute;
        white-space: nowrap;
        top: -27px;
        right: -45px;
    }

    .icon-8 {
        position: relative;
    }

    .icon-8 label {
        position: absolute;
        white-space: nowrap;
        top: -27px;
        right: -40px;
    }

    .icon-label {
        display: flex;
    }
    .icon-label label {
        padding: 0 5px;
    }
}

.ar {
    .border-all-section {
        border-left: 0px;
        border-radius: 0px 0px 5px 0px;
    }
    .border-top-section {
        border-radius: 0px 5px 0px 5px;
    }
    .icon-2 {
        margin-right: -13px;
    }
    .icon-2 label {
        margin-right: -55px;
    }
}
.en {
    .mui-speed-dial {
        & .MuiSpeedDial-root {
            right: 30px !important;
            left: inherit !important;
        }
        right: 30px !important;
        left: inherit !important;
    }

    .border-all-section {
        border-right: 0px;
        border-radius: 0 0px 0px 5px;
    }
    .border-top-section {
        border-radius: 5px 0 5px 0;
    }
    .icon-2 {
        margin-left: -13px;
    }
    .icon-2 label {
        margin-left: -55px;
    }
}
