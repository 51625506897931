.title {
    font-weight: bold !important;
    font-size: 0.9rem !important;
}

.sub-title {
    font-size: 0.8rem;
    padding: 0px 4px;
    margin-top: 0px;
}


.zoom {
    padding: -8px 0 0 0;
    position: relative;
}
.zoom img {
    height: 80px;
    width: 320px;
    image-rendering: pixelated;
    object-fit: contain;
}
