//start static varianles
$mainPortalColor: #2e49a2;
$primaryColor: #2366ab;
$accordion-headersColor: #1e76b552;
$whiteColor: #fff;
$darkGrayColor: #424242; 
$boxShadowColor: #dddcdc;
$breadCcrumb-hoverColor: #e0e0e0;
$gray93: #efeeee;
$boarder-headerColor: #e7e7e7;
$fontWeightRegular: 400;
//end static variables
.styled-mui-accordion {
    padding-top: 10px;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid silver;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    margin: 0 !important;
    & .Mui-expanded {
        height: 100% !important;
        min-height: 100% !important;
        align-items: center !important;
    }
    & .MuiAccordionSummary-root {
        height: 35px !important;
        min-height: 100% !important;
    }
}
.accordion-styled-summary {
    font-size: 18px;
    font-weight: bold;
    &.MuiAccordionSummary-root {
        // color: #2366ab !important;
        color: $primaryColor !important;
        font-weight: bolder;
        min-height: 48;
        height: 48;
    }
    &.MuiAccordionSummary-root.Mui-expanded {
        min-height: 48;
        height: 48;
    }
}

.MuiPaper-root:before {
    background-color: transparent !important;
}
.accordion-styled-summary-title {
    font-size: 1rem;
}
.styled-accordion-main-grid {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding-top: 5px !important;
    width: 100%;
}
.accordion-styled-border-header {
    background: $accordion-headersColor;
    border-radius: 3px 3px 0px 0px;
    width: 100%;
    font-size: 1rem;
    font-weight: bolder;
    margin-top: -11px !important;
}
#styled-avatar {
    background-color: $primaryColor !important;
    width: 30px !important;
    height: 30px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 15px !important;
}
#breadCrumb {
    .styled-breadcrumb {
        &.MuiChip-root {
            background-color: $whiteColor;
            // height: 24px;
            box-shadow: -1px 1px 6px 0px $boxShadowColor;
            color: $darkGrayColor;
            font-weight: $fontWeightRegular;
            text-transform: capitalize;
            cursor: pointer;
            &:hover,
            &:focus {
                background-color: $breadCcrumb-hoverColor;
            }
            &:active {
                box-shadow: $boxShadowColor;
                background-color: $whiteColor;
            }
            & .MuiChip-label {
                display: flex !important;
                align-self: center !important;
            }
        }
    }
    .style-breadCrumb-icon {
        &.MuiSvgIcon-root {
            width: 20px;
            height: 20px;
        }
    }
    .styled-breadcrumb-link {
        text-decoration: none;
        cursor: pointer;
    }
}
#spinner-container {
    display: flex;
    height: 100vh;
    margin-top: calc(50vh - 9vh);
    flex-grow: 1;
    align-content: center;
    justify-content: center;
}
#styled-backDrop {
    &.MuiBackdrop-root {
        z-index: 99;
        color: $whiteColor;
    }
}
#PageHeader {
    .styled-title {
        font-weight: bold;
    }
    .styled-border-header {
        border: 1px solid $boarder-headerColor;
        padding: 15px;
        background: $gray93;
        border-radius: 4px;
    }
}
#PaginationGrid {
    padding-top: 1rem;
    .pagination-text {
        align-self: center;
        padding-left: 10px;
    }
    .styled-pagination {
        display: flex;
        justify-content: center;
    }
}
#default-search {
    .default-search {
        padding-left: 0px;
        // padding-left: 15px;
        // margin: 10px;
    }
    .search-card-actions {
        width: 100%;
        position: relative;
        right: 4px !important;
        left: 4px !important;
    }
    .ar {
        .search-card-actions {
            padding-left: 1rem;
        }
    }
    .en {
        .search-card-actions {
            padding-right: 1rem;
        }
    }
    .search-card-actions {
        padding: 0 !important;
        & .MuiButton-containedPrimary {
            width: 30px !important;
            min-width: auto !important;
            margin: 0;
        }
        & .MuiButton-startIcon {
            margin: 0 !important;
        }
    }
    .search-card-default {
        padding: 0 !important;
        & .MuiButton-containedPrimary {
            width: 100% !important;
            min-width: auto !important;
            margin: 0 3px;
        }
        & .MuiButton-startIcon {
            margin: 0 !important;
        }
    }
}
.card-ins {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100% !important;
    margin: -30px 0 14px 0px;
    // padding-top: 0% !important;
    // height: 26%;
}
#Advanced-search {
    margin-top: 0.5rem;
    min-width: 100%;
    .advanced-actions-grid {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 3px;
        width: 100%;
    }
    .advanced-card-actions {
        padding: 8px 0 0 0 !important;
        & .MuiButton-containedPrimary {
            width: 30px !important;
            min-width: auto !important;
            margin: 0;
        }
        & .MuiButton-startIcon {
            margin: 0 !important;
        }
    }
}
.DDList-loader {
    width: 25px;
    /* the size */
    padding: 4px;
    /* the border thickness */
    background: #2e49a2;
    /* the color */
    aspect-ratio: 1;
    border-radius: 50%;
    --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    box-sizing: border-box;
    animation: load 1s linear infinite;
    //postioning
    margin-top: -29px;
    &.NormalDD {
        margin-top: -33px;
        margin-right: 7px;
    }
}

.ar {
    .DDList-loader {
        float: left;
        margin-left: 8px;
    }
}
.en {
    .DDList-loader {
        float: right;
        margin-right: 8px;
    }
}
@keyframes load {
    to {
        transform: rotate(1turn);
    }
}
// #speed-dial {
.ar {
}
.en {
    .mui-speed-dial {
        & .MuiSpeedDial-root {
            right: 30px !important;
            left: inherit !important;
        }
        right: 30px !important;
        left: inherit !important;
    }
}
// }