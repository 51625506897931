.list-item-active {
    // background-color: rgb(19 85 155) !important;
    // box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.1) !important;
    // border-radius: 30px;
}

.list-item {
    .MuiTypography-root {
        display: inline !important;
        font-size: 0.8rem;
        font-weight: 400;
    }
}
