#date-picker-container {
  .styled-date-picker {
      width: 100%;
  }

  .styled-date-text-field {
      width: 100% !important;
  }
}

.date-cleared {
  position: absolute !important;
  top: -0.2rem !important;
  margin: auto !important;
}

.ar {
  .date-cleared {
    left: 1.5rem !important;
  }
}

.en {
  .date-cleared {
    right: 1.5rem !important;
  }
}