body {
  background: #27272b;
}

.ar {
  .alertify .ajs-commands {
    right: 88% !important;
  }
}


.loading {
  zoom: 1.5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
}

.loading svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loading svg polyline#back {
  stroke: #1e76b54d;
}

.loading svg polyline#front {
  stroke: #1e76b5;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 48;
  animation: dash 1s linear infinite;
}

@-moz-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-o-keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  62.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}