* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: Cairo !important;
  font-size: 220px;
  font-weight: 200;
  margin: 0px;
  color: #2e49a2;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-family: Cairo !important;
  font-size: 27px;
  font-weight: 400;
  text-transform: uppercase;
  color: #2e49a2;
  background: #fafafa;
  padding: 8px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.notfound a {
  font-family: Cairo !important;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  color: #fafafa;
  text-transform: uppercase;
  padding: 10px 23px;
  background: #2e49a2;
  font-size: 17px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #fff;
  background: #211b19;
}

@media only screen and (max-width: 800px) {
  .notfound .notfound-404 h1 {
    font-size: 120px;
  }

  .notfound .notfound-404 h2 {
    font-family: Cairo !important;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #2e49a2;
    background: #fafafa;
    padding: 8px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 34px;
    left: 0;
    right: 0;
    width: 66%;
  }

  .notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0px auto 0px;
    z-index: -1;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}

.unauthorized-Error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}

.unauthorized-Error img {
  max-width: 28%;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 120px;
  }

  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }

  .notfound .notfound-404 h2 {
    font-size: 15px;
    padding: 8px 5px;
    bottom: 10px;
    width: 63%;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .notfound .notfound-404 h1 {
    font-size: 85px;
  }

  .notfound .notfound-404 {
    height: 148px;
    margin: 0px auto 10px;
  }

  .notfound .notfound-404 h2 {
    font-size: 10px;
    padding: 8px 5px;
    bottom: 27px;
    width: 62%;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}