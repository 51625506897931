.password-container {
    
}

.icon-password svg {
    width: 20px !important;
    height: 20px !important;
}

.user-container{ 
    overflow-y: scroll !important; 
    ::-webkit-scrollbar {
        height: 0.1rem;
        width: 0.5rem;
    } 
}

.title {
    font-weight: bold !important;
    font-size: 0.9rem !important;
}

.sub-title {
    font-size: 0.8rem;
    padding: 0px 4px;
    margin-top: 0px;
}
