.container-organization {
  display: flex;
  justify-content: center;
  margin-top: 22px !important;

  .MuiNativeSelect-select {
    color: #fff !important;
    // border-color: #fff !important;
  }

  .MuiNativeSelect-select option {
    color: #000000de !important;
    // border-color: #fff !important;
  }


  .MuiSvgIcon-root {
    color: #fff !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
  }




}

.en .container-organization {
  .MuiInputBase-root {
    margin-left: 8px !important;


  }
}


.ar .container-organization {
  .MuiInputBase-root {
    margin-right: 8px !important;

  }
}
.muiltr-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(255 255 255 / 38%) !important;
}
.muiltr-ciw10u.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: unset !important;
}