.file-base-container {
    .zoom {
        padding: -8px 0 0 0;
        position: relative;
    }

    .zoom img {
        height: 80px;
        width: 320px;
    }

    .zoom-menu-open img {
        height: 80px;
        width: 290px;
        object-fit: contain;
    }

    .file-button {
        cursor: pointer;
        color: #0d47a1;
        width: 35px;
        height: 35px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
            0px 1px 18px 0px rgb(0 0 0 / 12%);
        border-radius: 50%;
        background-color: #e0e0e0;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        margin: -6px 15px 0 0;
    }

    .file-button:hover {
        text-decoration: none;
        background-color: #d5d5d5;
    }

    .massage-error {
        color: red;
        font-weight: bold;
        padding: 5px 5px;
    }

    .reset-button {
        position: absolute;
        cursor: pointer;
        right: inherit;
    }

    .reset-button svg {
        position: absolute;
        cursor: pointer;
        left: 0;
        width: 35;
        padding: 5px;
    }
}

.logo-label {
    font-size: 11.4px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Cairo;
    font-weight: 400;
    line-height: 1.4375em;
    margin-top: 5px;
    padding-right: 7px;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
}


.massage-error-mui-label {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: inherit;
    font-family: Cairo;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #d32f2f;
}