
.table-container {
    width: 100%;
    margin-top: 10px;
    color: white;
    font-size: 13px;
    font-weight: normal !important;
    border-collapse: collapse;

    .table-header {
      background-color: #1E76B5;
      height: 28px;
    }

    .table-body {
      color: black;

      .MuiButtonBase-root {
        padding: 0;
        margin: 0;
        border-radius: 0;
      }

      tr:hover {
      }

      tr {
        width: 100%;
        text-align: center;
        vertical-align: middle;

        td {
          text-align: center;
          border-bottom: 1px solid silver;
          padding: 5px;

          .div-show-children-action,
          .div-select-action {
            display: inline-block;
            vertical-align: middle;
          }
          
          .container-out-of-contract{
            display: flex;
            align-items: center;
          }

          .out-of-contract{
            padding:0 5px;
          }

          .outOfContract {
            // display: inline-block;
            // width: 100% !important;
            // text-align: start;
            padding-inline-start: 5px;
          }
        }
      }
    }

    .tr-items-hiddin {
      display: none;
    }
  }

